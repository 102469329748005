import { useEffect } from "react"
import Carousel from 'react-bootstrap/Carousel'
import Container from 'react-bootstrap/Container'
import "./Projects.css"

function Projects() {
    useEffect(() => {
        document.title = "Projects"
        fetch('https://dev.bytebro.de/api/getPosts.php', { method: 'GET' })
            .then(res => res.json())
            .then(data => {
                console.log(data)
            });
    }, [])

    var interval = 10000;

    return (<Container>
        <div className="mobile">
            <Carousel className="carousel">
                <Carousel.Item interval={interval}>
                    <img src="resources/2022_1.jpg" className="d-block w-100" alt="2022_1" />
                </Carousel.Item>
                <Carousel.Item interval={interval}>
                    <img src="resources/2022_2.jpg" className="d-block w-100" alt="2022_2" />
                </Carousel.Item>
                <Carousel.Item interval={interval}>
                    <img src="resources/2022_3.jpg" className="d-block w-100" alt="2022_3" />
                </Carousel.Item>
                <Carousel.Item interval={interval}>
                    <img src="resources/2022_4.jpg" className="d-block w-100" alt="2022_4" />
                </Carousel.Item>
            </Carousel>
            <br />
            <br />
            <div className="post-info">
                <h5>Txiloli Feminino De Cachoeira</h5>
                <h6>2022, einmonatiger Arbeitsaufenthalt in São Tomé,</h6>
                <h6>anhaltendes Rechercheprojekt</h6>
            </div>
        </div>
        <div className="post-text">
            <p>Im Rahmen des Reisestipendiums der Kunstakademie Münster war ich von Anfang Januar 2022 bis Anfang Februar 2022 auf São Tomé and Príncipe. Während meiner rund 4-wöchigen Reise habe ich mich erkundigt, welche Rolle die Theaterform Tchiloli für das Land spielt, das schon seit Jahrhunderten dort aufgeführt wird. Gemeinsam mit meiner Schwester haben wir Kontakt zu Lokalen aufgenommen, um mehr über das Theaterstück und deren MitspielerInnen erfahren zu können. Innerhalb eines 45-minütigen Interviews haben mir sechs Frauen und zwei Männer Auskunft u.a. über die aktuelle Situation, die Möglichkeiten aufzuführen, Kostüme und Anlässe zum Spielen gegeben. Im Vorfeld habe ich mich mit dem historischen Kontext des Stücks befasst, und nahm an, dass es weiblichen Menschen bis dato verwehrt ist, Tchiloli zu spielen, obwohl es weibliche Rollen in dem gut 4-stündigen Stück gibt. Umso überraschter war ich, als ich durch einen Tipp die facebook-Seite einer Gruppe namens „Txiloli feminino de Cachoeira“ (dt.: weibliches Tchiloli von Cachoeira) fand – eine ausschließlich weibliche Gruppe junger Spielerinnen. Gegründet wurde die Gruppe von Otniel Diaz, der sich ebenfalls Zeit für ein Gespräch nahm, um zu erklären, wie die Gruppe zustandekam. Gemeinsam mit einem Kollegen initiierte er die Gruppe, die vor 2 Jahren zustandekam, und freut sich, die Gruppenleitung nun an Fany, ein Mitglied der Gruppe abgegeben zu haben, sodass die Gruppe mit etwas Unterstützung nun autonom ist.</p>
        </div>
        <div className="divider"></div>
        <div className="mobile">
            <Carousel className="carousel">
                <Carousel.Item interval={interval}>
                    <img src="resources/2021_1.jpg" className="d-block w-100" alt="2021_1" />
                </Carousel.Item>
                <Carousel.Item interval={interval}>
                    <img src="resources/2021_2.jpg" className="d-block w-100" alt="2021_2" />
                </Carousel.Item>
            </Carousel>
            <br />
            <br />
            <div className="post-info">
                <h5>o.T.</h5>
                <h6>2021, Cyanotypie, Papier auf Glas,</h6>
                <h6>10x10cm, Ausstellungsansicht</h6>
            </div>
        </div>
        <div className="post-text">
            <p>Als einziges Motiv an der Wand vor Kopf des Ausstellungsraums befindet sich eine Cyanotypie mit Sonnenbrillen-tragenden Frauen. Manche von ihnen sind Ordensschwestern, das erkennt man an ihrer Kleidung – der Rest trägt schlichte Kleider mit hohen Krägen im Stil der 1960er. Im Hintergrund ein Anschnitt eines Balkons mit Begrünung – ein Urlaubsbild?</p>
            <p>Der rechte und linke Bildrand schließt jeweils mit einem dunklen, blauen Streifen ab. Der Ausschnitt des Bildes wurde nachträglich verschoben; er lässt weder erkennen, wer noch was ursprünglich auf der Aufnahme Platz fand. Hell sticht das Kleid heraus, in dem die Frau vorne mittig mit Arm hinter dem Rücken steht, als bildeten die schwarzen Habite um sie herum eine flache Ebene, aus der sie sich herauswindet.</p>
            <p>In meiner Auseinandersetzung mit meiner familiären Geschichte ich auf ein Foto gestoßen, das auf mich wirkt, als würde es nicht zu ihrer Biografie gehören. Selbstbewusst und robust, wie sie wirkt - ihren Blick zur Kamera gerichtet mit geradem Oberkörper. Meine Oma war, seit ich denken kann, psychisch krank, und wurde auch so behandelt. In Fotos und Erzählungen ihrer Geschichte habe ich mich lange aufgehalten und habe meine Version, einen Anschnitt der Persönlichkeit, die sie vielleicht einmal war, gewählt.</p>
        </div>
        <div className="divider"></div>
        <div className="mobile">
            <Carousel className="carousel">
                <Carousel.Item interval={interval}>
                    <img src="resources/2020_1.jpg" className="d-block w-100" alt="2020_1" />
                </Carousel.Item>
                <Carousel.Item interval={interval}>
                    <img src="resources/2020_2.jpg" className="d-block w-100" alt="2020_2" />
                </Carousel.Item>
                <Carousel.Item interval={interval}>
                    <img src="resources/2020_3.jpg" className="d-block w-100" alt="2020_3" />
                </Carousel.Item>
            </Carousel>
            <br />
            <br />
            <div className="post-info">
                <h5>This is A Blue Warning</h5>
                <h6>2020, 2K, 9:00 min, film stills</h6>
            </div>
        </div>
        <div className="post-text">
            <p>This is A Blue Warning is a narrative dystopian video work that shows perspective representations of individuals who find themselves in ambigous situations of subliminal threat.</p>
            <p>This is A Blue Warning ist eine dystopische Videoarbeit, die die perspektivische Darstellung von Individuen zeigt, die sich in ambigen Situationen unterschwelliger Bedrohung befinden.</p>
            <p>Link</p>
        </div>
    </Container>);
}

export default Projects
